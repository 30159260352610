<template>
  <div class="add-cred-form">
    <h2 class="title">
      <span> {{ credText.title }}</span>
    </h2>

    <div class="cred-form-body" v-if="!loading">
      <div v-if="this.localName">
        <div class="name-label">Credential Name</div>
        <div class="name-value">{{ this.localName }}</div>
      </div>

      <div v-if="this.status">
        <div class="name-label">Status</div>
        <div :class="getStatusClasses(status)">{{ this.status }}</div>
        <div class="detail-value" v-if="this.statusDetail">
          {{ this.statusDetail }}
        </div>
      </div>

      <div class="select-inset-label" v-if="this.credStep == 1">
        <label>Credential Type</label>
        <n-select
          v-model:value="credTypeSelectValue"
          :options="credTypeArrayOptions"
          :on-update:value="credTypeOnUpdate"
        />
      </div>

      <div
        class="textarea-inset-label"
        v-if="
          this.credStep == 2 &&
          this.credTypeSelectValue === 'Google - service account'
        "
      >
        <label>JSON</label>
        <n-input
          v-model:value="jsonValue"
          type="textarea"
          :placeholder="jsonPlaceholder"
        />
      </div>

      <div class="info-block" v-if="credText.helpText">
        <div class="info-wrapper">
          <DisplaySvg name="info-circle" class="info-icon" />
        </div>
        <p>
          {{ credText.helpText }}
        </p>
      </div>

      <div class="site-error" v-if="error && !loading">{{ error }}</div>

      <div class="buttons">
        <button
          class="button white-btn"
          @click="this.closeClick"
          v-if="this.closeClick"
        >
          {{ credText.closeBtnText }}
        </button>
        <button
          class="button blue-btn"
          @click="this.addCred"
          v-if="credText.btnText"
        >
          {{ credText.btnText }}
        </button>
      </div>
    </div>

    <div class="site-loading" v-if="!error && loading">
      <LoadingSpinner /> Loading...
    </div>
  </div>
</template>

<script>
import CredentialService from "@/services/CredentialService";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NSelect, NInput } from "naive-ui";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";

export default {
  name: "AddCredForm",
  components: { NSelect, NInput, DisplaySvg, LoadingSpinner },
  props: [
    "type",
    "name",
    "orgid",
    "status",
    "statusDetail",
    "rootClientId",
    "metaRootClientId",
    "credentialid",
    "closeClick",
  ],
  data() {
    const step1_google_oauth = {
      title: "Add Credential",
      btnText: "Create",
      closeBtnText: "Cancel",
      helpText:
        "By authenticating your Google account, you safely grant AdTech DNA access to your organization's Google marketing services.",
    };

    const step2_google_oauth = {
      title: "Authenticate Credential",
      btnText:
        `${this.status}`.toLowerCase() == "pending" ? "Authenticate" : null,
      closeBtnText:
        `${this.status}`.toLowerCase() == "pending" ? "Cancel" : "Close",
      helpText: null,
    };

    const step1_google_service_account = {
      title: "Add Credential",
      btnText: "Continue",
      closeBtnText: "Cancel",
      helpText:
        "By authenticating a Google service account, you'll safely grant AdTech DNA access to your organization's Google marketing services for the specified service account.",
    };

    const step2_google_service_account = {
      title: "Submit Service Account Credential",
      btnText: "Create",
      closeBtnText: "Cancel",
      helpText:
        "Service accounts are application-specific credentials that are created through the GCP console. Reach out to the InfoTrust team to work through the process of setting up a service account for use with AdTech.",
    };

    const step1_meta_oauth = {
      title: "Add Credential",
      btnText: "Create",
      closeBtnText: "Cancel",
      helpText:
        "By authenticating your Meta account, you safely grant AdTech DNA access to your organization's Meta marketing services.",
    };

    const step2_meta_oauth = {
      title: "Authenticate Credential",
      btnText:
        `${this.status}`.toLowerCase() == "pending" ? "Authenticate" : null,
      closeBtnText:
        `${this.status}`.toLowerCase() == "pending" ? "Cancel" : "Close",
      helpText: null,
    };

    const jsonPlaceholder = JSON.stringify(
      {
        type: "service_account",
        project_id: "my-gcp-project",
        private_key_id: "xxxx",
        private_key: "xxxx",
        client_email: "xxxx",
        client_id: "xxxx",
        auth_uri: "https://...",
        token_uri: "https://...",
        auth_provider_x509_cert_url: "https://...",
        client_x509_cert_url: "https://...",
        universe_domain: "googleapis.com",
      },
      undefined,
      4
    );

    return {
      loading: false,
      error: null,
      credStep: 1,
      jsonValue: "",
      step1_google_oauth: step1_google_oauth,
      step2_google_oauth: step2_google_oauth,
      step1_google_service_account: step1_google_service_account,
      step2_google_service_account: step2_google_service_account,
      step1_meta_oauth: step1_meta_oauth,
      step2_meta_oauth: step2_meta_oauth,
      jsonPlaceholder: jsonPlaceholder,
      credText: step1_google_oauth,
      credTypeSelectValue: this.type ? this.type : "Google - oauth",
      localCredId: this.credentialid,
      localName: this.name,
      credTypeArrayOptions: [
        { label: "Google - oauth",
          value: "Google - oauth"
        }, {
          label: "Google - service account",
          value: "Google - service account",
        }, {
          label: "Meta - oauth",
          value: "Meta - oauth"
        }
      ],
    };
  },
  methods: {
    getStatusClasses(status) {
      return {
        "name-value": true,
        green: status === "ACTIVE",
        orange: status === "PENDING",
      };
    },
    credTypeOnUpdate(credTypeSelectValue) {
      this.credTypeSelectValue = credTypeSelectValue;

      if (`${credTypeSelectValue}`.toLowerCase() === "google - oauth") {
        // step 1 of creating a Google - oauth credential
        this.credText = this.step1_google_oauth;
      }

      if (
        `${credTypeSelectValue}`.toLowerCase() === "google - service account"
      ) {
        // step 1 of creating a Google - service account
        this.credText = this.step1_google_service_account;
      }

      if (
        `${credTypeSelectValue}`.toLowerCase() === "meta - oauth"
      ) {
        // step 1 of creating a Meta oauth credential
        this.credText = this.step1_meta_oauth;
      }
    },
    addCred() {
      if (
        this.credStep == 1 &&
        `${this.credTypeSelectValue}`.toLowerCase() === "google - oauth"
      ) {
        // step 1 of creating a Google - oauth credential
        this.createGoogleOauth();
      } else if (
        this.credStep == 2 &&
        `${this.credTypeSelectValue}`.toLowerCase() === "google - oauth"
      ) {
        // step 2 of creating a Google - oauth
        this.authGoogleOauth();
      } else if (
        this.credStep == 1 &&
        `${this.credTypeSelectValue}`.toLowerCase() ===
          "google - service account"
      ) {
        // step 1 of creating a Google - service account
        // no calls go out for this
        this.credStep = 2;
        this.credText = this.step2_google_service_account;
      } else if (
        this.credStep == 2 &&
        `${this.credTypeSelectValue}`.toLowerCase() ===
          "google - service account"
      ) {
        // step 2 of creating a Google - service account
        this.createGoogleServiceAccount();
      } else if (
        this.credStep == 1 &&
        `${this.credTypeSelectValue}`.toLowerCase() === "meta - oauth"
      ) {
        // step 1 of creating a Meta - oauth credential
        this.createMetaOauth();
      } else if (
        this.credStep == 2 &&
        `${this.credTypeSelectValue}`.toLowerCase() === "meta - oauth"
      ) {
        this.authMetaOauth();
      }
    },
    authGoogleOauth() {
      this.loading = true;
      this.error = null;

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        if (this.closeClick) {
          this.loading = false;
          this.closeClick();
        }
        return;
      }

      CredentialService.call("credentials.google.submitOAuthSecret", {
        credentialid: this.localCredId,
        redirectUrl: window.location.href,
      })
        .then((response) => {
          if (response.error) {
            this.triggerError({
              message:
                "credentials.google.submitOAuthSecret - error obj returned from api",
              error: response.error,
            });
            return Promise.resolve();
          }

          if (response.error || !response.result.redirectUrl) {
            this.loading = false;
            return (this.error = "Oops. Something went wrong.");
          }

          window.location.replace(response.result.redirectUrl);
        })
        .catch((error) => {
          this.triggerError({
            message: "credentials.google.submitOAuthSecret - catch",
            error: error,
          });
        });
    },
    createGoogleOauth() {
      this.loading = true;
      this.error = null;

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        this.localCredId = "1234";
        this.localName = "Example New Credential Name";
        this.credStep = 2;
        this.credText = {
          title: "Authenticate Credential",
          btnText: "Authenticate",
          closeBtnText: "Cancel",
          helpText: null,
        };
        this.loading = false;
        return;
      }

      CredentialService.call("credentials.google.enrollOAuthCredential", {
        orgid: this.orgid,
        clientIdCredentialId: this.rootClientId,
      })
        .then((response) => {
          if (response.error) {
            this.triggerError({
              message:
                "credentials.google.enrollOAuthCredential - error obj returned from api",
              error: response.error,
            });
            return Promise.resolve();
          }

          this.localCredId = response.result.credential.credentialid;
          this.localName = response.result.credential.name;
          this.credStep = 2;
          this.credText = {
            title: "Authenticate Credential",
            btnText: "Authenticate",
            closeBtnText: "Cancel",
            helpText: null,
          };
          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message: "credentials.google.enrollOAuthCredential - catch",
            error: error,
          });
          this.error = "Oops. Something went wrong.";
          this.loading = false;
        });
    },
    isJsonValid(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    authMetaOauth() {
      this.loading = true;
      this.error = null;

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        if (this.closeClick) {
          this.loading = false;
          this.closeClick();
        }
        return;
      }

      CredentialService.call("credentials.meta.submitOAuthSecret", {
        credentialid: this.localCredId,
        redirectUrl: window.location.href,
      })
        .then((response) => {
          if (response.error) {
            this.triggerError({
              message:
                "credentials.meta.submitOAuthSecret - error obj returned from api",
              error: response.error,
            });
            return Promise.resolve();
          }

          if (response.error || !response.result.redirectUrl) {
            this.loading = false;
            return (this.error = "Oops. Something went wrong.");
          }

          window.location.replace(response.result.redirectUrl);
        })
        .catch((error) => {
          this.triggerError({
            message: "credentials.meta.submitOAuthSecret - catch",
            error: error,
          });
        });
    },
    createMetaOauth() {
      this.loading = true;
      this.error = null;

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        this.localCredId = "5678";
        this.localName = "Example New Meta Credential Name";
        this.credStep = 2;
        this.credText = {
          title: "Authenticate Meta Credential",
          btnText: "Authenticate",
          closeBtnText: "Cancel",
          helpText: null,
        };
        this.loading = false;
        return;
      }

      CredentialService.call("credentials.meta.enrollOAuthCredential", {
        orgid: this.orgid,
        appCredentialId: this.metaRootClientId,
      })
        .then((response) => {
          if (response.error) {
            this.triggerError({
              message:
                "credentials.meta.enrollOAuthCredential - error obj returned from api",
              error: response.error,
            });
            return Promise.resolve();
          }

          this.localCredId = response.result.credential.credentialid;
          this.localName = response.result.credential.name;
          this.credStep = 2;
          this.credText = {
            title: "Authenticate Credential",
            btnText: "Authenticate",
            closeBtnText: "Cancel",
            helpText: null,
          };
          this.loading = false;
        })
        .catch((error) => {
          this.triggerError({
            message: "credentials.meta.enrollOAuthCredential - catch",
            error: error,
          });
          this.error = "Oops. Something went wrong.";
          this.loading = false;
        });
    },
    createGoogleServiceAccount() {
      this.loading = true;
      this.error = null;

      let errors = [];

      if (!this.jsonValue || this.jsonValue === "") {
        errors.push(
          "JSON is required in order to create a Google - Service Account credential. Please reach out to the InfoTrust team if you need assistance."
        );
      } else if (!this.isJsonValid(this.jsonValue)) {
        errors.push(
          "Oops. Your JSON is invalid. Please reach out to the InfoTrust team if you need assistance."
        );
      }

      if (errors.length > 0) {
        this.loading = false;
        return (this.error = errors.join(", "));
      }

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        this.loading = false;
        this.closeClick();
        return;
      }

      if (this.name) {
        // this service account already exists and just needs to be updated
        CredentialService.call("credentials.google.registerServiceAccount", {
          credentialid: this.localCredId,
          serviceAccountJson: JSON.parse(this.jsonValue),
        })
          .then((response) => {
            if (response.error) {
              this.triggerError({
                message:
                  "credentials.google.registerServiceAccount - error obj returned from api",
                error: response.error,
                info: "service account does not exist yet and needs to be created",
              });
              return Promise.resolve();
            }

            this.loading = false;
            this.closeClick();
          })
          .catch((error) => {
            this.triggerError({
              message: "credentials.google.registerServiceAccount - catch",
              error: error,
              info: "this service account already exists and just needs to be updated",
            });
            this.error = "Oops. Something went wrong.";
            this.loading = false;
          });
      } else {
        // service account does not exist yet and needs to be created
        CredentialService.call("credentials.google.registerServiceAccount", {
          orgid: this.orgid,
          serviceAccountJson: JSON.parse(this.jsonValue),
        })
          .then((response) => {
            if (response.error) {
              this.triggerError({
                message:
                  "credentials.google.registerServiceAccount - error obj returned from api",
                error: response.error,
                info: "service account does not exist yet and needs to be created",
              });
              return Promise.resolve();
            }

            this.loading = false;
            this.closeClick();
          })
          .catch((error) => {
            this.triggerError({
              message: "credentials.google.registerServiceAccount - catch",
              error: error,
              info: "service account does not exist yet and needs to be created",
            });
          });
      }
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = "Oops. Something went wrong.";
      this.loading = false;
    },
  },
  mounted() {
    if (`${this.type}`.toLowerCase() == "google - oauth" && this.name) {
      this.credStep = 2;
      this.credText = this.step2_google_oauth;
      this.credTypeSelectValue = "Google - oauth";
    }

    if (
      `${this.type}`.toLowerCase() == "google - service account" &&
      this.name
    ) {
      let credText = this.step2_google_service_account;
      credText.btnText = "Resubmit";
      credText.title = "Resubmit Service Account Credential";

      this.credStep = 2;
      this.credTypeSelectValue = "Google - service account";
      this.credText = credText;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
