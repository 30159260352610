<template>
  <div>
    <n-input type="textarea" v-model:value="fieldValue" class="w-100" />
  </div>
</template>

<script>
import { NInput, useMessage } from 'naive-ui'

export default {
  name: 'MixedField',
  components: {
    NInput
  },
  watch: {
    fieldValue: {
      handler: function (newVal) {
        if (this.isJsonString(newVal) === false) {
          this.message.alert('You did not provide valid JSON in a JSON field.');
          return;
        }
        this.$emit('update:modelValue', JSON.stringify(newVal));
      },
      deep: true
    }
  },
  props: [
    'modelValue'
  ],
  data() {
    return {
      fieldValue: [],
      message: useMessage()
    };
  },
  methods: {
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>