<template>
  <div class="object w-full" v-if="property.type == 'object'">
    <n-form-item class="white-box mb-5" :label="Object.prototype.hasOwnProperty.call(property.properties[propertyKey], 'label') ? property.properties[propertyKey].label : propertyKey" v-for="propertyKey of Object.keys(property.properties)" :key="propertyKey">
      <n-input-number
      v-model:value="fieldValue[propertyKey]"
      v-if="property.properties[propertyKey].type == 'number'"
      />
      <n-switch
      v-model:value="fieldValue[propertyKey]"
      v-if="property.properties[propertyKey].type == 'boolean'"
      />
      <MixedField
      v-if="property.properties[propertyKey].type == 'mixed'"
      v-model="fieldValue[propertyKey]"
      class="w-100"
      ></MixedField>
      <n-input
      class="w-full"
      v-model:value="fieldValue[propertyKey]"
      v-if="property.properties[propertyKey].type == 'string'"
      />
      <div class="description">{{ property.properties[propertyKey].description }}</div>
      <ArrayField
      v-if="property.properties[propertyKey].type == 'array'"
      :property="property.properties[propertyKey]"
      v-model="fieldValue[propertyKey]"></ArrayField>
    </n-form-item>
  </div>
</template>

<script>
import { NInput, NInputNumber, NSwitch, NFormItem } from 'naive-ui'
import ArrayField from '@/components/ManageAccount/Rules/fields/ArrayField'
import MixedField from '@/components/ManageAccount/Rules/fields/MixedField'

export default {
  name: 'ObjectField',
  components: {
    NInput,
    NInputNumber,
    NSwitch,
    NFormItem,
    ArrayField,
    MixedField
  },
  watch: {
    fieldValue: {
      handler: function (newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  },
  props: [
    'modelValue',
    'property'
  ],
  computed: {
    hasProperty() {
      return this.property != undefined;
    }
  },
  data() {
    return {
      fieldValue: {}
    };
  },
  created() {
    this.fieldValue = this.modelValue;
  }
}
</script>

<style lang="scss" scoped>

</style>