<template>
  <div class="view-sublinks">
    <LinkWithIcon 
      icon="profile" 
      text="Profile" 
      link="/manage-account/profile" 
      class="profile-icon" 
      :active="isActive('/manage-account/profile')" 
    />
    <LinkWithIcon 
      icon="users" 
      text="Users" 
      link="/manage-account/user-management" 
      class="users-icon" 
      :active="isActive('/manage-account/user-management')" 
    />
    <LinkWithIcon 
      icon="lock" 
      text="Credentials" 
      link="/manage-account/credential-management" 
      class="lock-icon" 
      :active="isActive('/manage-account/credential-management')" 
    />
    <LinkWithIcon 
      icon="lock" 
      text="Platform Connections" 
      link="/manage-account/container-management" 
      class="lock-icon" 
      :active="isActive('/manage-account/container-management')" 
    />
    <LinkWithIcon 
      icon="lock" 
      text="Rules" 
      link="/manage-account/rule-management" 
      class="lock-icon" 
      :active="isActive('/manage-account/rule-management')" 
    />
  </div>
</template>

<script>
import LinkWithIcon from "@/components/Shared/LinkWithIcon.vue";

export default {
  name: "Sublinks",
  components: {
    LinkWithIcon
  },
  methods: {
    isActive(link) {
      return window.location.pathname === link;
    },
  },
};
</script>

<style scoped>

</style>