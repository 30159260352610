<template>
  <div class="remove-cred">
    <h2 class="title">Remove Platform Connection</h2>

    <div class="confirm-text" style="display: none;">
      Remove <span>{{ this.name }}</span>
    </div>
    <div class="confirm-text">Confirm by typing "Remove" below</div>

    <div class="input-inset-label">
      <label>Confirm</label>
      <NInput v-model:value="removeConfirmInputValue" placeholder="Remove" />
    </div>

    <div class="error" v-if="removeError">{{ removeError }}</div>

    <div class="buttons">
      <button class="button white-btn" @click="closeClick" v-if="closeClick">
        Cancel
      </button>
      <button class="button red-btn" @click="removeCred">Remove</button>
    </div>
  </div>
</template>

<script>
import MetadataService from "@/services/MetadataService";
import { NInput } from "naive-ui";

export default {
  name: "RemoveForm",
  components: { NInput },
  props: [
    "type",
    "name",
    "orgid",
    "status",
    "statusDetail",
    "rootClientId",
    "containerid",
    "closeClick",
  ],
  data() {
    return {
      removeError: null,
      removeConfirmInputValue: null,
      NODE_ENV: process.env.NODE_ENV,
    };
  },
  methods: {
    removeCred() {
      // error validation
      this.removeError = null;
      let errors = [];

      if (
        !this.removeConfirmInputValue ||
        this.removeConfirmInputValue.toLowerCase() !== "remove"
      ) {
        errors.push(`please type "remove" to confirm`);
      }

      if (errors.length > 0) {
        return (this.removeError = errors.join(", "));
      }

      // if this is the demo env, stop here and don't actually make any calls
      if (process.env.NODE_ENV === "demo") {
        if (this.closeClick) {
          this.closeClick();
        }
        return;
      }

      MetadataService.call("analyticscontainers.delete", {
        containerid: this.containerid,
      })
      .then((response) => {
        if (response.error) {
          this.triggerError({
            message: "analyticscontainers.delete - error obj returned from api",
            error: response.error,
          });
          return Promise.resolve();
        }

        if (this.closeClick) {
          this.closeClick();
        }
      })
      .catch((error) => {
        this.triggerError({
          message: "analyticscontainers.delete - catch",
          error: error,
        });
      });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.removeError = "Error - please try again";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";

.remove-cred {
  width: 100%;

  .confirm-text {
    color: $navy;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;

    span {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $red;
    }

    &:last-child {
      margin-bottom: 10px;
    }
  }

  .red-btn {
    background-color: $red;
    color: $white;

    @include hover {
      background-color: $red !important;
    }
  }

  .white-btn {
    margin-right: 16px;
  }

  .small-btn {
    border-radius: 25px;
    margin-bottom: 30px;
  }

  &.widget {
    align-items: flex-start;
  }

  button {
    margin: 20px 0 0 0;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .error {
    text-align: right;
    width: 100%;
    font-size: 14px;
    color: $red;
    margin-top: 5px;
  }
}
</style>
