<template>
  <div class="w-full top-field">
    <div class="required" v-if="'required' in property && property.required === true">Required</div>
    <n-input-number
    v-model:value="fieldValue"
    clearable
    :required="property.required"
    v-if="property.type == 'number'"
    />
    <n-switch
    v-model:value="fieldValue"
    v-if="property.type == 'boolean'"
    />
    <n-input
    class="w-full"
    v-model:value="fieldValue"
    v-if="property.type == 'string' && property.disposition != 'jobstorage_filename' && 'enum' in property != true"
    />
    <EnumField
    class="w-100"
    v-if="property.type == 'string' && 'enum' in property"
    v-model="fieldValue"
    :property="property"
    ></EnumField>
    <ObjectField
    v-if="property.type == 'object'"
    v-model="fieldValue"
    :property="property"
    ></ObjectField>
    <ArrayField
    v-if="property.type == 'array' && Object.prototype.hasOwnProperty.call(property.elements, 'properties')"
    v-model="fieldValue"
    :property="property"
    ></ArrayField>
    <ArrayField
    v-if="property.type == 'array' && !Object.prototype.hasOwnProperty.call(property.elements, 'enum')"
    v-model="fieldValue"
    :property="property"
    ></ArrayField>
    <EnumArrayField
    class="w-100"
    v-if="property.type == 'array' && 'enum' in property.elements"
    v-model="fieldValue"
    :property="property"
    ></EnumArrayField>
    <div class="description" v-if="property.description">{{ property.description }}</div>
  </div>
</template>

<script>
import { NInput, NInputNumber, NSwitch } from 'naive-ui'
import ArrayField from '@/components/ManageAccount/Rules/fields/ArrayField'
import EnumField from '@/components/ManageAccount/Rules/fields/EnumField'
import EnumArrayField from '@/components/ManageAccount/Rules/fields/EnumArrayField'
import ObjectField from '@/components/ManageAccount/Rules/fields/ObjectField'

export default {
  components: {
    NInput,
    NInputNumber,
    NSwitch,
    ArrayField,
    EnumField,
    EnumArrayField,
    ObjectField,
  },
  props: [
    'modelValue',
    'propertyKey',
    'property'
  ],
  watch: {
    fieldValue: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  },
  data() {
    return {
      fieldValue: null
    };
  },
  created() {
    this.fieldValue = this.modelValue;
  }
}
</script>

<style lang="scss">
.top-field {

  > .required {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-style: italic;
  color: var(--n-tab-text-color-active);
  }

}
</style>