<template>
  <div>
    <div class="inputs">
      <n-form-item :label="label">
        <n-dynamic-input
        v-model:value="fieldValue"
        placeholder=""
        :min="0"
        :max="10"
        :on-create="onCreate"
        :item-style="property.type"
        v-if="Object.prototype.hasOwnProperty.call(property.elements, 'properties')"
        >
          <template #default="{ value }">
            <div v-for="key in Object.keys(property.elements.properties)" :key="key">
              <label>{{ Object.prototype.hasOwnProperty.call(property.elements.properties[key], 'label') ? property.elements.properties[key].label : key }}</label>
              <n-input class="mr-3" v-model:value="value[key]" type="text" v-if="property.elements.properties[key].type == 'string' && Object.prototype.hasOwnProperty.call(property.elements.properties[key], 'enum') == false" />
              <n-select v-model:value="value[key]" :options="selectOptions[key]" v-if="Object.prototype.hasOwnProperty.call(property.elements.properties[key], 'enum')"></n-select>
              <!-- Weird hack to get the DOM to refresh with the reactive options -->
              <select class="mr-3" v-if="Object.prototype.hasOwnProperty.call(property.elements.properties[key], 'enum')" style="display: none; position: absolute; top: -100%; opacity: 0;">
                <option :value="selectOption.label" v-for="selectOption in selectOptions[key]" :key="selectOption.value">{{ selectOption.label }}</option>
              </select>
            </div>
          </template>
        </n-dynamic-input>
        <n-dynamic-input v-else
        v-model:value="fieldValue"
        placeholder=""
        :min="0"
        :max="10"
        :on-create="onCreate"
        ></n-dynamic-input>
      </n-form-item>
    </div>
  </div>
</template>

<script>
import { NDynamicInput, NFormItem, NInput, NSelect } from 'naive-ui'

export default {
  name: 'ArrayField',
  components: {
    NDynamicInput,
    NFormItem,
    NInput,
    NSelect
  },
  watch: {
    fieldValue: {
      handler: function (newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  },
  props: [
    'modelValue',
    'label',
    'property'
  ],
  computed: {
    hasProperty() {
      return this.property != undefined;
    }
  },
  data() {
    return {
      fieldValue: [],
      selectOptions: {}
    };
  },
  methods: {
    onCreate() {
      let newObject = {};
      if (Object.prototype.hasOwnProperty.call(this.property.elements, 'properties') != false) {
        Object.keys(this.property.elements.properties).forEach(key => {
          newObject[key] = '';
        });
      } else {
        if (this.property.elements.type == 'string') {
          newObject = '';
        }
      }
      return newObject;
    },
    buildObject() {
      if (Array.isArray(this.modelValue) && this.modelValue.length != 0) {
        this.fieldValue = this.modelValue;
      }
      // if (Object.prototype.hasOwnProperty.call(this.property.elements, 'properties') != false) {
      //   let object = {};
      //   Object.keys(this.property.elements.properties).forEach(key => {
      //     object[key] = '';
      //   });
      //   this.fieldValue.push(object);
      // }
    },
    buildOptions() {
      if (Object.prototype.hasOwnProperty.call(this.property.elements, 'properties') == false) return;
      Object.keys(this.property.elements.properties).forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(this.property.elements.properties[key], 'enum')) return;
        let options = [];
        this.property.elements.properties[key].enum.forEach(arrayItem => {
          options.push({
            label: arrayItem,
            value: arrayItem
          });
        });
        this.selectOptions[key] = options;
      });
    }
  },
  mounted() {
    this.buildObject();
    this.buildOptions();
  }
}
</script>

<style lang="scss" scoped>
.n-dynamic-input {
display: block;

  .n-dynamic-input-item {
  align-items: center;
  justify-content: center;

    .n-dynamic-input-item__action {
    margin: auto !important;
    }

  }

}

.n-dynamic-input-item {
margin-bottom: 1rem;

  &:first-child {
  
    label {
    display: block;
    }

  }

  > div {
  margin-right: 1rem;
  }

  label {
  font-weight: bold;
  margin-bottom: .5rem;
  display: none;
  }

}

.n-select {
min-width: 190px;
}

.form-item .form-item {

  > .n-form-item-label {
  margin-bottom: 0px;
  }

}
</style>