<template>
  <div class="w-full">
    <n-select
    v-model:value="fieldValue"
    :options="options"
    class="w-full"
    clearable
    ></n-select>
  </div>
</template>

<script>
import { NSelect } from 'naive-ui'

export default {
  name: 'EnumField',
  components: {
    NSelect
  },
  watch: {
    fieldValue: {
      handler: function (newVal) {
        this.$emit('update:modelValue', newVal);
      },
      deep: true
    }
  },
  props: [
    'modelValue',
    'property'
  ],
  computed: {
    hasProperty() {
      return this.property != undefined;
    },
    options() {
      console.log('Making options');
      let options = [];
      if ('enum' in this.property == false) return [];
      this.property.enum.forEach((value, index) => {
        options.push({
          label: this.property.enum[index],
          value: value
        });
      });
      console.log(options);
      return options;
    }
  },
  data() {
    return {
      fieldValue: []
    };
  },
  mounted() {
    this.fieldValue = this.modelValue;
    console.log(this.property);
  }
}
</script>

<style lang="scss" scoped>
.n-select {
min-width: 190px;
}

.form-item .form-item {

  > .n-form-item-label {
  margin-bottom: 0px;
  }

}
</style>