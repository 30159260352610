<template>
  <div class="add-cred-form">
    <h2 class="editing-label">Editing Rule: {{ edit.rule.name }}</h2>

    <n-form
    :label-width="80"
    :size="'medium'"
    v-if="ruleTemplate != null && Object.prototype.hasOwnProperty.call(ruleTemplate, 'optionsschema')"
    >
      <n-form-item class="white-box p-3 mb-5" :label="Object.prototype.hasOwnProperty.call(ruleTemplate.optionsschema.properties[propertyKey], 'label') ? ruleTemplate.optionsschema.properties[propertyKey].label : propertyKey" :path="propertyKey" v-for="propertyKey in Object.keys(ruleTemplate.optionsschema.properties)" :key="propertyKey">
        <SchemaField :propertyKey="propertyKey" v-model="optionValues[propertyKey]" :property="ruleTemplate.optionsschema.properties[propertyKey]"></SchemaField>
      </n-form-item>
    </n-form>

    <div class="bottom">
      <div>
        <n-switch v-if="enabled != null" v-model:value="enabled" />
      </div>
      <div class="buttons">
        <button class="button white-btn" @click="this.closeClick" v-if="this.closeClick">
          Close
        </button>
        <button class="button blue-btn" @click="save()">
          Save
        </button>
      </div>
    </div>

    <div class="site-loading" v-if="!error && loading">
      <LoadingSpinner /> Loading...
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NFormItem, NForm, NSwitch } from "naive-ui";
import SchemaField from "@/components/ManageAccount/Rules/SchemaField.vue";
import AdtechService from "@/services/AdtechService";

export default {
  name: "EditForm",
  components: { NFormItem, NForm, NSwitch, LoadingSpinner, SchemaField },
  props: [
    "closeClick",
    "edit"
  ],
  data() {
    return {
      loading: false,
      error: null,
      ruleTemplate: null,
      enabled: null,
      optionValues: {}
    };
  },
  methods: {
    getRuleTemplateOptions() {
      AdtechService.call("ruletemplates.get", { ruletemplateid: this.edit.rule.ruletemplateid }).then(response => {
        this.ruleTemplate = response.result.ruletemplate;
        this.addOptionValues();
      });
    },
    save() {
      const filterUndefined = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).filter(([, value]) => value !== undefined)
        );
      };
      AdtechService.call('rules.update', {
        ruleinstanceid: this.edit.rule.ruleinstanceid,
        updates: {
          options: filterUndefined(this.optionValues),
          enabled: this.enabled
        },
      }).then(response => {
        if (response.error) {
          this.triggerError(response.error);
          return;
        }
        this.$emit('form-saved', response.result.rule);
        this.closeClick();
      });
    },
    triggerError(obj) {
      console.log("%cError", "color: white; background-color: red", obj);
      this.error = "Oops. Something went wrong.";
      this.loading = false;
    },
    addOptionValues() {
      this.optionValues = this.edit.rule.options;
    }
  },
  mounted() {
    this.getRuleTemplateOptions();
    this.enabled = this.edit.rule.enabled;
    console.log(this.edit);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
