<template>
  <section class="auth-credentials view-rule">
    <section class="view-header">
      <h1 class="view-title">Manage Account</h1>
      <Sublinks></Sublinks>
    </section>

    <Overlay v-if="isAddOverlayOpen" :closeClick="closeOverlay">
      <div class="widget add-cred-overlay">
        <div @click="closeOverlay()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <AddForm :closeClick="closeOverlay" :orgid="isAddOverlayOpen.orgid" v-on:form-saved="onFormSaved" />
      </div>
    </Overlay>

    <Overlay v-if="isEditOverlayOpen" :closeClick="closeOverlay">
      <div class="widget add-cred-overlay">
        <div @click="closeOverlay()">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <EditForm :closeClick="closeOverlay" :orgid="isEditOverlayOpen.orgid" :edit="isEditOverlayOpen" v-on:form-saved="onFormSaved" />
      </div>
    </Overlay>

    <Overlay v-if="isRemoveOverlayOpen" :closeClick="closeOverlay">
      <div class="widget add-cred-overlay">
        <div @click="closeOverlay">
          <DisplaySvg name="x" class="close-icon" />
        </div>
        <RemoveForm :closeClick="closeOverlay" :ruleinstanceid="isRemoveOverlayOpen.ruleinstanceid"
          :orgid="isRemoveOverlayOpen.orgid" />
      </div>
    </Overlay>

    <div class="workarea">
      <div class="widget full auth-credentials">
        <div class="table-wrapper">
          <div class="table-header cred-table-header">
            <div class="manage-account-cred-title">
              <h2>Rules</h2>
              <p>Manage your organization's rules for AdTech DNA.</p>
            </div>
            <div class="filters" v-if="$store.state.organizationOptions">
              <UpdateOrgForm />
            </div>
          </div>
          <div class="table-header cred-table-sub-header">
            <div></div>
            <div class="filters" v-if="!loading">
              <NInput v-model:value="searchQuery" placeholder="Search" v-if="tableData.length > 0 && !loading">
                <template #suffix>
                  <DisplaySvg name="search" class="search-icon" />
                </template>
              </NInput>
              <button class="button blue-btn" :onClick="() => {
                add(false);
              }
                ">
                Add Rule
                <DisplaySvg name="plus" class="plus-icon" />
              </button>
            </div>
          </div>
        </div>
        <NDataTable v-if="
          tableData.length > 0 &&
          !error &&
          !loading
        " :columns="tableColumns" :data="filteredData" :bordered="false" :pagination="pagination"
          class="full manage-account-creds--users-table"></NDataTable>
        <div class="site-message" v-if="tableData.length == 0 && !loading">
          The {{ $store.state.organization }} organization currently has no rules.
        </div>
        <div class="site-error" v-if="error && !loading">{{ error }}</div>
        <div class="site-loading" v-if="!error && loading">
          <LoadingSpinner /> Loading...
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import router from "@/router";
import AdtechService from "@/services/AdtechService";
import EllipsisDropdown from "@/components/Shared/EllipsisDropdown.vue";
import DisplaySvg from "@/components/Shared/DisplaySvg.vue";
import Overlay from "@/components/Shared/Overlay.vue";
import Sublinks from "@/components/ManageAccount/Sublinks.vue";
import AddForm from "@/components/ManageAccount/Rules/AddForm.vue";
import EditForm from "@/components/ManageAccount/Rules/EditForm.vue";
import RemoveForm from "@/components/ManageAccount/Rules/RemoveForm.vue";
import UpdateOrgForm from "@/components/ManageAccount/UpdateOrgForm.vue";
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NDataTable, NInput, NSwitch } from "naive-ui";
import { h } from "vue";

export default {
  name: "ManageAccountRules",
  components: {
    NDataTable,
    NInput,
    DisplaySvg,
    AddForm,
    EditForm,
    RemoveForm,
    Overlay,
    UpdateOrgForm,
    LoadingSpinner,
    Sublinks
  },
  computed: {
    filteredData() {
      return this.tableData.filter((item) =>
        Object.values(item).some((value) =>
          value !== null && value !== undefined &&
          value.toString().toLowerCase().includes(this.searchQuery.toLowerCase())
        )
      );
    },
    pagination() {
      return {
        pageSize: 10,
        disabled: this.filteredData.length <= 10 ? true : false,
      };
    },
  },
  methods: {
    add() {
      // Opens the overlay in "Add" mode
      this.isAddOverlayOpen = {
        orgid: this.$store.state.organization,
      };
    },
    edit(rule) {
      // Opens the overlay in "Edit" mode, passing the existing rule data
      this.isEditOverlayOpen = {
        rule: rule || null,
        orgid: this.$store.state.organization,
      };
    },
    remove(rule) {
      this.isRemoveOverlayOpen = {
        ruleinstanceid: rule ? rule.ruleinstanceid : false,
        orgid: this.$store.state.organization,
      };
    },
    closeOverlay() {
      this.isAddOverlayOpen = false;
      this.isEditOverlayOpen = false;
      this.isRemoveOverlayOpen = false;
      this.getData();
    },
    async getData() {
      if (this.getDataActive) return;

      this.getDataActive = true;
      this.loading = true;

      try {
        const fetchAllPages = async (pageToken = null, accumulatedData = []) => {
          const params = {
            orgid: this.$store.state.organization,
          };

          if (pageToken) {
            params.pageToken = pageToken;
          }

          const response = await AdtechService.call("rules.list", params);

          if (!response || !response.result || !response.result.rules) {
            return accumulatedData;
          }

          const currentPageData = response.result.rules.map((rule) => ({
            ...rule
          }));

          const allData = [...accumulatedData, ...currentPageData];

          if (response.result.hasMore) {
            return fetchAllPages(response.result.pageToken, allData);
          }

          return allData;
        };

        const tableData = await fetchAllPages();
        this.tableData = [...tableData];
        this.setTableColumns();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        this.getDataActive = false;
        this.loading = false;
      }
    },
    setTableColumns() {
      const editRowOnClick = (row) => {
        this.edit(row);
      };
      const removeRowOnClick = (row) => {
        this.remove(row);
      };

      this.tableColumns = [
        {
          title: "Name",
          minWidth: 200,
          render(row) {
            return h("p", {}, row.name);
          },
        },
        {
          title: "",
          key: "action",
          minWidth: 100,
          render(row) {
            const editOnClick = () => {
              editRowOnClick(row);
            };
            const removeOnClick = () => {
              removeRowOnClick(row);
            };

            const options = [
              {
                key: `${row.ruleinstanceid}-edit`,
                onClick: editOnClick,
                text: "Edit",
              },
              {
                key: `${row.ruleinstanceid}-remove`,
                onClick: removeOnClick,
                text: "Remove",
                class: "red",
              },
            ];

            return h(
              EllipsisDropdown,
              {
                options: options,
              },
              {
                // Provide an empty slot function for better performance
                default: () => null,
              }
            );
          },
        },
        {
          key: 'enabled',
          render: (row) => {
            return h(NSwitch, {
              value: row.enabled,
              'onUpdate:value': (value) => {
                console.log(value); // Sync the value with the model
                this.updateRuleFields(row.ruleinstanceid, { enabled: value });
                row.enabled = value;
              }
            })
          }
        },
      ];
    },
    onFormSaved(rule) {
      this.getData();
      this.isEditOverlayOpen = rule;
    },
    getDataOrgCheck() {
      if (
        (this.$store.state.organizationOptions &&
          this.$store.state.organizationOptions.length > 0 &&
          this.$store.state.organization &&
          this.$store.state.userData &&
          this.$store.state.orgHierarchy &&
          this.$store.state.orgHierarchy.length > 0) ||
        process.env.NODE_ENV === "demo"
      ) {
        this.getData();
      }
    },
    updateRuleFields(ruleinstanceid, updates) {
      AdtechService.call('rules.update', {
        ruleinstanceid: ruleinstanceid,
        updates: updates
      });
    }
  },
  data() {
    return {
      error: false,
      loading: true,
      getDataActive: false, // only used to tell if the current getData call is running
      NODE_ENV: process.env.NODE_ENV,
      isAddOverlayOpen: false,
      isRemoveOverlayOpen: false,
      isEditOverlayOpen: false,
      searchQuery: "",
      tableColumns: [],
      tableData: [],
      hierarchyString: "root",
      rootClientId: null,
    };
  },
  watch: {
    "$store.state.orgHierarchy": function() {
      this.getDataOrgCheck();
    },
    "$store.state.organizationOptions": function() {
      this.getDataOrgCheck();
    },
    "$store.state.userData": function() {
      this.getDataOrgCheck();
    },
    "$store.state.organization": function() {
      this.getDataOrgCheck();
    },
  },
  mounted() {
    if (this.$store.state.organization != null) this.getData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_helpers.scss";
</style>
