<template>
  <section class="manage-account-profile view-container">
    <section class="view-header">
      <h1 class="view-title">Manage Account</h1>
      <Sublinks></Sublinks>
    </section>
    <div class="workarea">
      <ProfileWidget />
    </div>
  </section>
</template>

<script>
import ProfileWidget from "@/components/ManageAccount/ProfileWidget.vue";
import Sublinks from "@/components/ManageAccount/Sublinks.vue";

export default {
  name: "ManageAccountProfile",
  components: { ProfileWidget, Sublinks },
  data() {
    return {
      NODE_ENV: process.env.NODE_ENV,
      showTabs: false,
    };
  },
  watch: {
    "$store.state.organizationOptions": function () {
      if (
        this.$store.state.organizationOptions &&
        this.$store.state.organizationOptions.length > 0
      ) {
        this.getData();
      }
    },
  },
  methods: {
    getData() {
      this.$store.state.organizationOptions.map((org) => {
        if (org.role.toLowerCase() === "admin" && this.showTabs === false) {
          this.showTabs = true;
        }
      });
    },
  },
  mounted() {
    if (
      this.$store.state.organizationOptions &&
      this.$store.state.organizationOptions.length > 0
    ) {
      this.getData();
    }
  },
};
</script>

<style lang="scss">
@import "@/styles/_helpers.scss";

.manage-account-profile {
  h1 {
    margin: 0;
  }
  .global-options {
    align-items: flex-start;
  }

  .invite-users-overlay {
    h2 {
      text-align: left;
    }
  }
}
</style>
