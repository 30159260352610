<template>
  <div class="add-cred-form">
    <div class="select-inset-label">
      <label>Rule Name</label>
      <n-input v-model:value="name" />
    </div>

    <div class="select-inset-label">
      <label>Rule Template</label>
      <n-select
        v-model:value="ruleTemplate"
        :options="ruleTemplateOptions"
        @update:value="fetchTemplateSchema"
      />
    </div>

    <!-- Render dynamic fields if optionsschema exists -->
    <n-form
      v-if="ruleTemplateSchema"
      :label-width="80"
      :size="'medium'"
    >
      <n-form-item
        v-for="(property, key) in ruleTemplateSchema.properties"
        :key="key"
        :label="property.label || key"
        :path="key"
      >
        <SchemaField
          :propertyKey="key"
          v-model="dynamicValues[key]"
          :property="property"
        />
      </n-form-item>
    </n-form>

    <div class="site-error" v-if="error && !loading">{{ error }}</div>

    <div class="buttons">
      <button class="button white-btn" @click="this.closeClick" v-if="this.closeClick">
        Close
      </button>
      <button class="button blue-btn" @click="add">
        Add
      </button>
    </div>

    <div class="site-loading" v-if="!error && loading">
      <LoadingSpinner /> Loading...
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/Shared/LoadingSpinner.vue";
import { NSelect, NInput, NForm, NFormItem } from "naive-ui";
import SchemaField from "@/components/ManageAccount/Rules/SchemaField.vue";
import AdtechService from "@/services/AdtechService";

export default {
  name: "AddForm",
  components: { NSelect, NInput, LoadingSpinner, NForm, NFormItem, SchemaField },
  props: ["closeClick"],
  data() {
    return {
      loading: false,
      error: null,
      name: "",
      ruleTemplate: null,
      ruleTemplateOptions: [],
      ruleTemplateSchema: null, // Store fetched schema here
      dynamicValues: {}, // Store dynamic field values here
    };
  },
  methods: {
    getRuleTemplateOptions() {
      AdtechService.call("ruletemplates.listUsableRuleTemplates", { orgid: this.$store.state.organization })
        .then((response) => {
          this.ruleTemplateOptions = response.result.ruletemplates.map((template) => ({
            label: template.name,
            value: template.ruletemplateid,
          }));
        })
        .catch((error) => {
          this.triggerError(error);
        });
    },
    fetchTemplateSchema(ruleTemplateId) {
      this.ruleTemplateSchema = null; // Clear previous schema
      this.dynamicValues = {}; // Clear previous values
      if (!ruleTemplateId) return;

      AdtechService.call("ruletemplates.get", { ruletemplateid: ruleTemplateId })
        .then((response) => {
          this.ruleTemplateSchema = response.result.ruletemplate.optionsschema || null;
          this.initializeDynamicValues();
        })
        .catch((error) => {
          this.triggerError(error);
        });
    },
    initializeDynamicValues() {
      if (!this.ruleTemplateSchema) return;

      const populateValues = (properties) => {
        return Object.keys(properties).reduce((acc, key) => {
          const prop = properties[key];

          if (prop.type === "object" && prop.properties) {
            // Recursively populate nested object properties
            acc[key] = populateValues(prop.properties);
          } else {
            // Use default value or null for other types
            acc[key] = prop.default || null;
          }

          return acc;
        }, {});
      };

      this.dynamicValues = populateValues(this.ruleTemplateSchema.properties);
    },
    add() {
      this.loading = true;
      AdtechService.call("rules.create", {
        data: {
          orgid: this.$store.state.organization,
          name: this.name,
          ruletemplateid: this.ruleTemplate,
          options: this.dynamicValues, // Include dynamic values in request
        },
      })
        .then((response) => {
          if (response.error) {
            this.triggerError(response.error);
            return;
          }
          this.$emit("form-saved", response.result.rule);
          this.closeClick();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    triggerError(obj) {
      console.error("Error:", obj);
      this.error = "Oops. Something went wrong.";
      this.loading = false;
    },
  },
  mounted() {
    this.getRuleTemplateOptions();
  },
};
</script>

<style lang="scss" scoped>
.top-field > .required {
  margin-top: 0px;
}
</style>